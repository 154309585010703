import { useAppAuthorisationList } from "@gocardless/api/dashboard/app-authorisation";
import {
  Box,
  ButtonLayout,
  ButtonSize,
  ButtonVariant,
  ColorScheme,
  JustifyContent,
  Link,
  Menu,
  MenuRole,
  Overflow,
  PlainLink,
  ResponsiveValue,
  Separator,
  Space,
  Tag,
  TagColor,
  TagSize,
  TagVariant,
  Visibility,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import router from "next/router";
import { useLayoutEffect, useState } from "react";
import { XERO_APP_ID } from "src/common/constants/partner_apps";
import {
  useUserPermissions,
  useUserHasWritePermissions,
} from "src/common/hooks/user-permissions/useUserPermissions";
import { ButtonPosition } from "src/common/trackingEventAttributes";
import { TrackingEvent } from "src/common/trackingEvents";
import { useSegment } from "src/technical-integrations/segment/useSegment";
import { useOrganisation } from "src/queries/organisation";
import { useDefaultCreditor } from "src/queries/creditor";
import { OptimizelyDecideOption } from "@optimizely/react-sdk";
import { SetupPaymentsEnabled } from "src/components/routes/SetupPayments/SetupPaymentsEnabled";
import { useOptimizelyVariation } from "src/technical-integrations/optimizely/useOptimizelyVariation";
import { OptimizelyFlag } from "src/technical-integrations/optimizely/constants";

import GoCardlessLogo from "../GoCardlessLogo";
import { LinkBuilder, Route } from "../routing";
import { ToTranslate } from "../i18n";

import GettingStartedMenuLink from "./GettingStartedMenuLink";
import { LeaveFeedback } from "./LeaveFeedback";
import TryBankAccountData from "./TryBankDataLink";
import { DoubleRewards } from "./DoubleRewards";

interface NavigationProps {
  highlightedMenuLink?: Route;
  height?: ResponsiveValue<number | string>;
}

const PROTECT_PLUS_ROUTES = [
  Route.Chargebacks,
  Route.RiskSettings,
  Route.Monitoring,
  Route.Blocklist,
];

const SUCCESS_PLUS_ROUTES = [
  Route.SuccessPlusReporting,
  Route.SuccessPlusConfigure,
  Route.SuccessPlus,
  Route.IntelligentRetries,
];

const PAYMENTS_ROUTES = [
  Route.Payments,
  Route.SubscriptionTemplates,
  Route.Payouts,
];

const DEVELOPER_ROUTES = [Route.Developers, Route.Events];

const Navigation: React.FC<NavigationProps> = ({
  highlightedMenuLink,
  height = "100%",
}) => {
  const getAccentState = (route: Route) =>
    highlightedMenuLink === route ? "visible" : "hidden";

  const [isProtectPlusExpanded, setIsProtectPlusExpanded] = useState(false);
  const [isDevelopersExpanded, setIsDevelopersExpanded] = useState(false);
  const [isPaymentsExpanded, setIsPaymentsExpanded] = useState(false);
  const [isSuccessPlusExpanded, setIsSuccessPlusExpanded] = useState(false);

  const organisation = useOrganisation();
  const defaultCreditor = useDefaultCreditor();
  const isActivated = defaultCreditor?.activated ?? false;

  // We want to show Success+ sidebar menu item only to organisations that
  // are eligible for it. Note: this logic is duplicated on enterprise dashboard
  const isEligibleForSuccessPlus = organisation?.is_eligible_for_auto_retries;

  useLayoutEffect(() => {
    setIsProtectPlusExpanded(
      !!highlightedMenuLink && PROTECT_PLUS_ROUTES.includes(highlightedMenuLink)
    );
    setIsSuccessPlusExpanded(
      !!highlightedMenuLink && SUCCESS_PLUS_ROUTES.includes(highlightedMenuLink)
    );
    setIsPaymentsExpanded(
      !!highlightedMenuLink && PAYMENTS_ROUTES.includes(highlightedMenuLink)
    );
    setIsDevelopersExpanded(
      !!highlightedMenuLink && DEVELOPER_ROUTES.includes(highlightedMenuLink)
    );
  }, [highlightedMenuLink]);

  const { sendEvent } = useSegment();
  const { data: appAuthorisations } = useAppAuthorisationList();
  const showXeroLink = appAuthorisations?.app_authorisations?.some(
    (app) => app.links?.app === XERO_APP_ID
  );

  const userPermissions = useUserPermissions();
  const isAdmin = !!userPermissions.isAdmin;
  const { hasWritePermissions } = useUserHasWritePermissions();

  const hideHomePage =
    !!organisation?.links?.payment_provider ||
    !!organisation?.is_multi_creditor;

  /**
   * There are some options we want to limit visibility and access to for Embed customers. This includes:
   * - Protect+ options except Chargebacks
   * - Subscription Templates
   * - the Create Payment button.
   * There are some options we want to explicitly show for Embed customers in the navigation bar. This includes:
   * - Mandates
   */
  const isPaymentProvider = !!organisation?.links?.payment_provider;
  const canCreatePayments = hasWritePermissions && !isPaymentProvider;
  const canInviteCustomers = hasWritePermissions && isActivated;

  const { isVariationOn: isDoubleRewardsPromotionEnabled } =
    useOptimizelyVariation({
      flag: OptimizelyFlag.SPARK_DOUBLE_REWARDS_PROMOTION,
      options: {
        decideOptions: [OptimizelyDecideOption.DISABLE_DECISION_EVENT],
      },
    });

  const { isVariationOn: isReferOn } = useOptimizelyVariation({
    flag: OptimizelyFlag.REFERRAL_SCHEME_V2,
    options: {
      decideOptions: [OptimizelyDecideOption.DISABLE_DECISION_EVENT],
    },
  });
  const { isVariationOn: isInPipeEmbedExperiment } = useOptimizelyVariation({
    flag: OptimizelyFlag.ASAP_PAYMENTS_PIPE_EMBED,
  });

  return (
    <Box
      height={height}
      layout="flex"
      flexDirection="column"
      justifyContent={JustifyContent.SpaceBetween}
      overflowY={Overflow.Scroll}
      css={{
        "::-webkit-scrollbar": {
          width: 0,
          background: "transparent",
        },
        "::-webkit-scrollbar-thumb": {
          borderRadius: "6px",
          background: "transparent",
        },
      }}
    >
      <Menu.Root role={MenuRole.Menu} colorScheme={ColorScheme.OnDark}>
        <Box spaceAbove={2} spaceBelow={2} gutterH={1.5}>
          <Space v={0.5} />
          <LinkBuilder route={Route.Home}>
            {(result) => (
              <PlainLink {...result} layout={ButtonLayout.Inline}>
                <GoCardlessLogo variant="light" size="md" />
              </PlainLink>
            )}
          </LinkBuilder>
          <Space v={1.5} />
          <SetupPaymentsEnabled defaultNode={<TryBankAccountData />}>
            {/* don't show try bank account data*/}
            <></>
          </SetupPaymentsEnabled>
        </Box>
        <NavigationItems
          getAccentState={getAccentState}
          isProtectPlusExpanded={isProtectPlusExpanded}
          setIsProtectPlusExpanded={setIsProtectPlusExpanded}
          isSuccessPlusExpanded={isSuccessPlusExpanded}
          setIsSuccessPlusExpanded={setIsSuccessPlusExpanded}
          isEligibleForSuccessPlus={isEligibleForSuccessPlus}
          isAdmin={isAdmin}
          sendEvent={sendEvent}
          isDevelopersExpanded={isDevelopersExpanded}
          setIsDevelopersExpanded={setIsDevelopersExpanded}
          isPaymentsExpanded={isPaymentsExpanded}
          setIsPaymentsExpanded={setIsPaymentsExpanded}
          hideHomePage={hideHomePage}
          isPaymentProvider={isPaymentProvider}
          canInviteCustomers={canInviteCustomers}
          canCreatePayments={canCreatePayments}
          shouldShowPipeItem={isInPipeEmbedExperiment}
        />

        {showXeroLink ? (
          <>
            <Box gutterH={0.5}>
              <Separator spacing={1} opacity={0.4} />
            </Box>
            <Menu.Link href="https://xero.gocardless.com/">
              <Trans id="page.gocardless-for-xero">GoCardless for Xero</Trans>
            </Menu.Link>
          </>
        ) : null}
      </Menu.Root>
      <Box>
        {isDoubleRewardsPromotionEnabled && isReferOn ? (
          <Menu.Root role={MenuRole.Menu} colorScheme={ColorScheme.OnDark}>
            <DoubleRewards />
          </Menu.Root>
        ) : null}
        <Menu.Root role={MenuRole.Menu} colorScheme={ColorScheme.OnDark}>
          <LeaveFeedback>
            {({ onClick }) => (
              <Menu.Button onClick={onClick}>
                <Trans id="leave-feedback.header">Leave feedback</Trans>
              </Menu.Button>
            )}
          </LeaveFeedback>
        </Menu.Root>
      </Box>
    </Box>
  );
};

interface NavigationItemsProps {
  getAccentState: (route: Route) => "visible" | "hidden";
  isProtectPlusExpanded: boolean;
  setIsProtectPlusExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  isSuccessPlusExpanded: boolean;
  setIsSuccessPlusExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  isEligibleForSuccessPlus?: boolean;
  isAdmin: boolean;
  hideHomePage: boolean;
  isPaymentProvider: boolean;
  canCreatePayments: boolean;
  canInviteCustomers: boolean;
  shouldShowPipeItem: boolean;
}

const NavigationItems: React.FC<
  NavigationItemsProps & {
    isDevelopersExpanded: boolean;
    setIsDevelopersExpanded: React.Dispatch<React.SetStateAction<boolean>>;
    isPaymentsExpanded: boolean;
    setIsPaymentsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
    sendEvent: (name: string, params?: {} | undefined) => void;
  }
> = ({
  getAccentState,
  isProtectPlusExpanded,
  setIsProtectPlusExpanded,
  isEligibleForSuccessPlus,
  isSuccessPlusExpanded,
  setIsSuccessPlusExpanded,
  isAdmin,
  isDevelopersExpanded,
  setIsDevelopersExpanded,
  isPaymentsExpanded,
  setIsPaymentsExpanded,
  sendEvent,
  hideHomePage,
  isPaymentProvider,
  canCreatePayments,
  canInviteCustomers,
  shouldShowPipeItem,
}) => (
  <>
    {!hideHomePage ? (
      <SetupPaymentsEnabled
        defaultNode={
          <LinkBuilder route={Route.Home}>
            {(result) => (
              <Menu.Link {...result} accent={getAccentState(Route.Home)}>
                <Trans id="Home">Home</Trans>
              </Menu.Link>
            )}
          </LinkBuilder>
        }
      >
        <GettingStartedMenuLink getAccentState={getAccentState} />
      </SetupPaymentsEnabled>
    ) : (
      <LinkBuilder route={Route.Creditors}>
        {(result) => (
          <Menu.Link {...result} accent={getAccentState(Route.Creditors)}>
            <Trans id="Creditors">Creditors</Trans>
          </Menu.Link>
        )}
      </LinkBuilder>
    )}

    <Menu.Expander
      title={<Trans id="Payments">Payments</Trans>}
      expanded={isPaymentsExpanded}
      onToggle={() => {
        setIsPaymentsExpanded((prev) => !prev);
      }}
    >
      <LinkBuilder route={Route.Payments}>
        {(result) => (
          <Menu.Link {...result} accent={getAccentState(Route.Payments)}>
            <Trans id="Payments">Payments</Trans>
          </Menu.Link>
        )}
      </LinkBuilder>
      {!isPaymentProvider && (
        <LinkBuilder route={Route.SubscriptionTemplates}>
          {(result) => (
            <Menu.Link
              {...result}
              accent={getAccentState(Route.SubscriptionTemplates)}
            >
              <Trans id="subscription-templates">Subscription templates</Trans>
            </Menu.Link>
          )}
        </LinkBuilder>
      )}

      <LinkBuilder route={Route.Payouts}>
        {(result) => (
          <Menu.Link {...result} accent={getAccentState(Route.Payouts)}>
            <Trans id="Payouts">Payouts</Trans>
          </Menu.Link>
        )}
      </LinkBuilder>
    </Menu.Expander>
    <LinkBuilder route={Route.Customers}>
      {(result) => (
        <Menu.Link {...result} accent={getAccentState(Route.Customers)}>
          <Trans id="Customers">Customers</Trans>
        </Menu.Link>
      )}
    </LinkBuilder>
    {isPaymentProvider && (
      <LinkBuilder route={Route.Mandates}>
        {(result) => (
          <Menu.Link {...result} accent={getAccentState(Route.Mandates)}>
            <Trans id="Mandates">Mandates</Trans>
          </Menu.Link>
        )}
      </LinkBuilder>
    )}
    <Visibility visible={isEligibleForSuccessPlus ? "block" : "none"}>
      <Menu.Expander
        title={<Trans id="page.success-plus">Success+</Trans>}
        expanded={isSuccessPlusExpanded}
        onToggle={() => {
          setIsSuccessPlusExpanded((prev) => !prev);
        }}
      >
        <LinkBuilder route={Route.SuccessPlusReporting}>
          {(result) => (
            <Menu.Link
              {...result}
              accent={getAccentState(Route.SuccessPlusReporting)}
              data-intercom-target="s-plus-menu-reporting"
            >
              <Trans id="success-plus-reporting">Reporting</Trans>
            </Menu.Link>
          )}
        </LinkBuilder>
        <LinkBuilder route={Route.SuccessPlusConfigure}>
          {(result) => (
            <Menu.Link
              {...result}
              accent={getAccentState(Route.SuccessPlusConfigure)}
              data-intercom-target="s-plus-menu-config"
            >
              <Trans id="success-plus.configure">Configure</Trans>
            </Menu.Link>
          )}
        </LinkBuilder>
      </Menu.Expander>
    </Visibility>
    <Menu.Expander
      title={<Trans id="Protect+">Protect+</Trans>}
      expanded={isProtectPlusExpanded}
      onToggle={() => {
        setIsProtectPlusExpanded((prev) => !prev);
      }}
    >
      <Visibility visible={isPaymentProvider ? "none" : "block"}>
        <LinkBuilder route={Route.Monitoring}>
          {(result) => (
            <Menu.Link {...result} accent={getAccentState(Route.Monitoring)}>
              <Trans id="sidebar-menu.protect-plus.monitoring-nested-link">
                Monitoring
              </Trans>
            </Menu.Link>
          )}
        </LinkBuilder>
        <LinkBuilder route={Route.RiskSettings}>
          {(result) => (
            <Menu.Link {...result} accent={getAccentState(Route.RiskSettings)}>
              <Trans id="sidebar-menu.protect-plus.risk-settings-nested-link">
                Risk settings
              </Trans>
            </Menu.Link>
          )}
        </LinkBuilder>
        <LinkBuilder route={Route.Blocklist}>
          {(result) => (
            <Menu.Link {...result} accent={getAccentState(Route.Blocklist)}>
              <Trans id="page.blocklist">Blocklist</Trans>
            </Menu.Link>
          )}
        </LinkBuilder>
      </Visibility>

      <LinkBuilder route={Route.Chargebacks}>
        {(result) => (
          <Menu.Link {...result} accent={getAccentState(Route.Chargebacks)}>
            <Trans id="page.chargebacks">Chargebacks</Trans>
          </Menu.Link>
        )}
      </LinkBuilder>
    </Menu.Expander>

    <Menu.Expander
      title={<Trans id="Developers">Developers</Trans>}
      expanded={isDevelopersExpanded}
      onToggle={() => {
        setIsDevelopersExpanded((prev) => !prev);
      }}
    >
      {isAdmin && (
        <LinkBuilder route={Route.Developers}>
          {(result) => (
            <Menu.Link {...result} accent={getAccentState(Route.Developers)}>
              <Trans id="Developers">Developers</Trans>
            </Menu.Link>
          )}
        </LinkBuilder>
      )}
      <LinkBuilder route={Route.Events}>
        {(result) => (
          <Menu.Link {...result} accent={getAccentState(Route.Events)}>
            <Trans id="Events">Events</Trans>
          </Menu.Link>
        )}
      </LinkBuilder>
    </Menu.Expander>
    {shouldShowPipeItem ? (
      <LinkBuilder route={Route.Capital}>
        {(result) => (
          <Menu.Link {...result} accent={getAccentState(Route.Capital)}>
            <ToTranslate>Capital</ToTranslate>
            <Space h={0.75} layout="inline" />
            <Tag
              variant={TagVariant.Tinted}
              size={TagSize.Xs}
              colorScheme={ColorScheme.OnDark}
              color={TagColor.Success}
            >
              <Trans>New</Trans>
            </Tag>
          </Menu.Link>
        )}
      </LinkBuilder>
    ) : null}

    {canCreatePayments && (
      <Box
        spaceAbove={2}
        spaceBefore={1.5}
        spaceAfter={1.5}
        data-testid="new-payment-button"
      >
        <LinkBuilder
          route={Route.RequestPayments}
          onClick={() => {
            sendEvent(
              TrackingEvent.REQUEST_PAYMENT_CREATE_PAYMENT_CTA_CLICKED,
              {
                page: router.pathname,
                position: ButtonPosition.SIDENAV,
              }
            );
          }}
        >
          {(result) => (
            <Link
              {...result}
              variant={ButtonVariant.PrimaryOnDark}
              size={ButtonSize.Sm}
              layout={ButtonLayout.Full}
            >
              <Trans id="page.create-payment">Create payment</Trans>
            </Link>
          )}
        </LinkBuilder>
      </Box>
    )}

    {canInviteCustomers ? (
      <Box spaceAbove={0.75} spaceBefore={1.5} spaceAfter={1.5}>
        <LinkBuilder
          route={Route.CustomersCreate}
          routeParams={{ id: "link" }}
          onClick={() => {
            sendEvent(TrackingEvent.CUSTOMERS_ADD_CUSTOMER_CTA_CLICKED, {
              page: router.pathname,
              position: ButtonPosition.SIDENAV,
            });
          }}
        >
          {(result) => (
            <Link
              css={{
                color: "#F1F252",
                boxShadow:
                  "inset 0 0 0 2px #F1F252,var(--flux-focus-ring, 0 0 0 0 transparent)",
              }}
              {...result}
              variant={ButtonVariant.SecondaryOnDark}
              size={ButtonSize.Sm}
              layout={ButtonLayout.Full}
            >
              <Trans id="customers.invite-customers">Invite customers</Trans>
            </Link>
          )}
        </LinkBuilder>
      </Box>
    ) : null}
  </>
);

export default Navigation;
